import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { AppRoutes, DateFormats, RolesValues } from '@constants';
import { useTheme } from '@innowise-group/mui-kit';
import { useFormContext } from 'react-hook-form';
import { ReportUnion, ReportTypeUnion, ReportButtonProps } from './components';
import { useReportManager, FiltersConfig, useReportManagerState } from './use-report-manager.hook';
import { chartReportIcons, tableReportIcons } from './report-manager.data';
import { useCompressedStateInQuery, useProfileAPI, useResizeObserver, SelectOption } from '@innowise-group/core';
import { ReportFormValues, TinyState } from './report-manager.types';
import { useNavigate } from 'react-router-dom';
import { DesktopView } from './components/desktop-view';
import { MobileView } from './components/mobile-view';

interface ReportManagerProps {
  onFiltersSubmit: (args: TinyState) => void;
  onExport: (args: TinyState) => void;
}

const mapIds = (options: Partial<SelectOption>[]) => options.map(({ value }) => value);

export const makeStateTiny = (args: ReportFormValues) => {
  const tinyState: TinyState = {
    ...args,
    vacancyClaims: mapIds(args.vacancyClaims),
    vacancyClaimAuthors: mapIds(args.vacancyClaimAuthors),
    candidateResponsible: mapIds(args.candidateResponsible),
    sources: mapIds(args.sources),
    statuses: mapIds(args.statuses),
    statusAuthors: mapIds(args.statusAuthors),
    vacancies: mapIds(args.vacancies),
  };
  return tinyState;
};

const ReportManager: FC<ReportManagerProps> = ({ onFiltersSubmit, onExport }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { profile } = useProfileAPI();
  const [state, setState] = useCompressedStateInQuery<TinyState>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const { t } = useTranslation();

  const { config, selectedOptionsLoaded } = useReportManager<ReportFormValues>(state);
  const isUpdatedStateFromQuery = useRef(false);

  const { watch, setValue, reset } = useFormContext<ReportFormValues>();
  const { updateGlobalState } = useReportManagerState(reset);
  const report = watch('report');

  const startDate = watch('dateMin') || null;
  const endDate = watch('dateMax') || null;

  const periodLabel = useMemo(() => {
    const formattedStartDate = startDate ? format(new Date(startDate), DateFormats.DayFirst) : '';
    const formattedEndDate = endDate ? format(new Date(endDate), DateFormats.DayFirst) : '';
    const period = formattedStartDate && formattedEndDate ? `${formattedStartDate} - ${formattedEndDate}` : '';
    return isDesktopView ? (period ? t('pages.analytics.period', { period }) : '') : period;
  }, [startDate, endDate, isDesktopView]);

  const reportTypeIcons: ReportButtonProps['icons'] = useMemo(() => {
    if (report === ReportUnion.Chart) return chartReportIcons;
    if (report === ReportUnion.Table) return tableReportIcons;
  }, [report]);

  const reportTypeVariants: ReportButtonProps['variants'] = useMemo(() => {
    if (report === ReportUnion.Chart)
      return {
        primary: ReportTypeUnion.Round,
        secondary: ReportTypeUnion.Linear,
      };
    if (report === ReportUnion.Table)
      return {
        primary: ReportTypeUnion.Horizontal,
        secondary: ReportTypeUnion.Vertical,
      };
  }, [report]);

  const switcherDisabled = useMemo(() => {
    if (
      window.location.pathname.includes(AppRoutes.AnalyticsRequestsPage) ||
      window.location.pathname.includes(AppRoutes.AnalyticsEmployeePage) ||
      window.location.pathname.includes(AppRoutes.AnalyticsExitsPage) ||
      window.location.pathname.includes(AppRoutes.AnalyticsSummaryPage)
    )
      return true;
    return profile?.role?.name !== RolesValues.Admin && profile?.role?.name !== RolesValues.Supervisor;
  }, [profile, window.location.pathname]);

  const switcherTypeDisabled = useMemo(() => {
    if (window.location.pathname.includes(AppRoutes.AnalyticsStatusPage) && report === ReportUnion.Table) return true;
  }, [report, window.location.pathname]);

  const statusAuthorDisabled = useMemo(() => {
    return window.location.pathname.includes(AppRoutes.AnalyticsEmployeePage);
  }, [window.location.pathname]);

  const statusDisabled = useMemo(() => {
    return window.location.pathname.endsWith(AppRoutes.AnalyticsStatusPage);
  }, [window.location.pathname]);

  const submitButtonDisabled = useMemo(() => {
    if (
      window.location.pathname.endsWith(AppRoutes.AnalyticsRequestsPage) &&
      profile?.role?.name !== RolesValues.Admin &&
      profile?.role?.name !== RolesValues.Supervisor
    )
      return true;
    return (
      window.location.pathname.endsWith(AppRoutes.AnalyticsDefaultPage) &&
      profile?.role?.name !== RolesValues.Admin &&
      profile?.role?.name !== RolesValues.Supervisor
    );
  }, [profile, window.location.pathname]);

  const toggleButton = useCallback(() => setExpanded((prev) => !prev), []);

  useEffect(() => {
    const subscription = watch((args, { name }) => {
      if (name === 'report') {
        if (args.report === ReportUnion.Chart) setValue('reportType', ReportTypeUnion.Linear);
        if (args.report === ReportUnion.Table) setValue('reportType', ReportTypeUnion.Horizontal);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const preselectedOptionMapper = (
    config: Omit<FiltersConfig<ReportFormValues>, 'dateMin' | 'dateMax' | 'report' | 'reportType'>,
    field: keyof Omit<FiltersConfig<ReportFormValues>, 'dateMin' | 'dateMax' | 'report' | 'reportType' | 'dateRange'>,
  ) =>
    config?.[field]?.options?.reduce((acc, val) => {
      if (state?.[field]?.includes(val.value)) {
        return [...acc, val];
      }
      return acc;
    }, []);

  useEffect(() => {
    const preselectedOptions = {
      vacancyClaimAuthors: preselectedOptionMapper(config, 'vacancyClaimAuthors'),
      vacancyClaims: preselectedOptionMapper(config, 'vacancyClaims'),
      candidateResponsible: preselectedOptionMapper(config, 'candidateResponsible'),
      sources: preselectedOptionMapper(config, 'sources'),
      statuses: preselectedOptionMapper(config, 'statuses'),
      statusAuthors: preselectedOptionMapper(config, 'statusAuthors'),
      vacancies: preselectedOptionMapper(config, 'vacancies'),
    };
    if (selectedOptionsLoaded && !isUpdatedStateFromQuery.current) {
      isUpdatedStateFromQuery.current = true;
      reset((prev) => ({
        ...prev,
        ...preselectedOptions,
        ...(state?.report && { report: state.report }),
        ...(state?.reportType && { reportType: state.reportType }),
        ...(state?.dateMin && { dateMin: new Date(state.dateMin) }),
        ...(state?.dateMax && { dateMax: new Date(state.dateMax) }),
      }));
    }
  }, [config]);

  const updateState = (args: ReportFormValues) => {
    const tinyState = makeStateTiny(args);
    setState(tinyState);
  };

  const applyFilters = (args: ReportFormValues) => {
    const body = makeStateTiny(args);
    onFiltersSubmit(body);
    updateState(args);
  };

  const onExportHandle = (args: ReportFormValues) => {
    const body = makeStateTiny(args);
    onExport(body);
  };

  const handleStatusChange = useCallback(
    (onChange: (value: string | SelectOption[], reason?: string) => void) =>
      (value: string | SelectOption[], reason?: string) => {
        onChange(value, reason);
        if (window.location.pathname.includes(AppRoutes.AnalyticsLettersPage) && !value.length) {
          updateGlobalState('statuses', []);
          navigate(AppRoutes.AnalyticsTeamWorkingPage);
        }
      },
    [window.location.pathname],
  );

  const Component = isDesktopView ? DesktopView : MobileView;
  return (
    <Component
      toggleButton={toggleButton}
      applyFilters={applyFilters}
      handleStatusChange={handleStatusChange}
      onExportHandle={onExportHandle}
      reportTypeIcons={reportTypeIcons}
      statusDisabled={statusDisabled}
      config={config}
      expanded={expanded}
      reportTypeVariants={reportTypeVariants}
      periodLabel={periodLabel}
      submitButtonDisabled={submitButtonDisabled}
      switcherTypeDisabled={switcherTypeDisabled}
      switcherDisabled={switcherDisabled}
      statusAuthorDisabled={statusAuthorDisabled}
    />
  );
};

export { ReportUnion, ReportTypeUnion };

export default memo(ReportManager);
