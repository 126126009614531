import { RolesValues } from '@constants';
import { useProfileAPI } from '@innowise-group/core';
import React from 'react';
import { Navigate } from 'react-router-dom';

export interface RoleGuardProps extends React.PropsWithChildren {
  roles: RolesValues[];
  isRoute?: boolean;
  redirectPath?: string;
}

const RoleGuard: React.FC<RoleGuardProps> = ({ roles, children, isRoute, redirectPath = '/' }) => {
  const { profile } = useProfileAPI();
  return roles.includes(profile?.role?.name) ? (
    <React.Fragment>{children}</React.Fragment>
  ) : isRoute ? (
    <Navigate to={redirectPath} />
  ) : null;
};

export default React.memo(RoleGuard);
